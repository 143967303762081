import React from "react"
import Page from "../components/page"
import Wrap from "../components/wrap"

const NotFoundPage = () => (
  <Page title="404: Not Found">
    <Wrap>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Wrap>
  </Page>
)

export default NotFoundPage
